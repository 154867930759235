import React from 'react'
import PropTypes from 'prop-types'
import GlobalLayout from 'components/GlobalLayout'
import { graphql } from 'gatsby'
import { css } from '@emotion/core'
import { SPACERS } from 'utils/styleHelpers'

function CookiePolicy({ location, data }) {
	const {
		allMarkdownRemark: { edges },
	} = data
	const {
		node: { html },
	} = edges[0]

	return (
		<GlobalLayout location={location} isAgeModalOverriden={true}>
			<div
				css={css`
						margin: ${SPACERS.XL};
					`}
				dangerouslySetInnerHTML={{ __html: html }}
			/>
		</GlobalLayout>
	)
}

CookiePolicy.propTypes = {
	location: PropTypes.object,
	data: PropTypes.object,
}

export const pageQuery = graphql`
	query cookiePolicyQuery {
		allMarkdownRemark(filter: { frontmatter: { title: { eq: "Cookie Policy" } } }) {
			edges {
				node {
					html
				}
			}
		}
	}
`

export default CookiePolicy
